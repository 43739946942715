:root {
  --color-brand: #09b850 !important;
  --bs-primary: #09b850 !important;
  --bs-btn-primary: #09b850 !important;
}

.bg-brand {
  background-color: var(--color-brand);
}

.text-brand {
  color: var(--color-brand);
}

.btn-primary {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #09b850 !important;
  --bs-btn-border-color: #09b850 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #089c44 !important;
  --bs-btn-hover-border-color: #079340 !important;
  --bs-btn-focus-shadow-rgb: 46, 195, 106 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #079340 !important;
  --bs-btn-active-border-color: #078a3c !important;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #09b850 !important;
  --bs-btn-disabled-border-color: #09b850 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-translucent {
  background-color: #ffffff62 !important;
}

.blur {
  filter: blur(5px);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}