.intro-container {
    margin-top: 50px;
}

.intro-slogan h1 {
    font-size: 5rem;
    font-weight: bold;
}

.intro-img-glom {
    width: 500px;
    height: 500px;
    -webkit-box-shadow:0px 0px 300px 0px var(--color-brand);
    -moz-box-shadow: 0px 0px 300px 0px var(--color-brand);
    box-shadow: 0px 0px 300px 0px var(--color-brand);
    object-fit: cover;
}

.email-container {
    width: 75% !important;
}

.email-container input {
    border: none;
    outline: none;
}

.scratch-svg {
    top: -15px;
    left: -17px;
}

.intro-img-sub {
    width: 250px;
    border: 5px solid white;
    top: -40px;
    right: 0px;
}

@media screen and (max-width: 1400px) {
    .scratch-svg {
        top: -15px;
        left: -60px;
    }
}

@media screen and (max-width: 1200px) {
    .intro-right-col {
        margin-top: 100px;
    }

    .intro-slogan h1 {
        font-size: 4rem;
    }

    .intro-img-glom {
        width: 100%;
        height: auto;
    }

    .intro-img-sub {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .intro-slogan h1 {
        font-size: 3rem;
    }

    .email-container {
        width: 100% !important;
    }

    .email-container input {
        width: 70px;
    }

    .scratch-svg {
        display: none;
    }
}