.services-container {
    width: 100%;
    background: #343a40 url('../../assets/svgs/pattern.svg') no-repeat 10px 10px;
}

.service-card {
    position: relative;
}

.service-logo {
    padding: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-brand);
    border-radius: 100%;
    position: absolute;
    right: 20px;
    top: -20px;
}

@media screen and (max-width: 768px) {
    .service-card {
        margin-bottom: 30px;
    }
}